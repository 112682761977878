:root {
    --palette-1: #FFF;
    --palette-2: #0582CA;
    --palette-3: #003554;
    --palette-4: #051923;
}

::-webkit-scrollbar {
    width: 14px;
    padding: 5px 2px;
    margin: 0 30px;
}

::-webkit-scrollbar-track {
    background-color: var(--palette-1);
    margin: 0 30px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--palette-3); 
    border-radius: 7px;
    margin-top: 20px;
    /* border-right: 3px transparent solid; */
    /* border: 4px transparent solid; */
    background-clip: padding-box;
}

/* ::-webkit-scrollbar-thumb:hover {
    background-color: var(--palette-3); 
} */
  