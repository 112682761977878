@import './variables.scss';

.tableHeader {
    color: $text_color_bg;
}

.layout-root {
    background: $color_background;
    width: 100%;
    min-height: 100%;

    .layout-page {
        background: inherit;
    }

    .layout-content {
        // margin-left: 200px;
        margin-top: 80px;
        min-height: 100%;
        padding-top: 20px;
        padding-left: 10px;
        background: inherit;
        color: $text_color;

        .ant-layout-content {
            width: 100%;
            min-height: 100%;
        }
    }
}