@import "../../style/variables.scss";

.ant-layout {
  .ant-layout-sider {
    width: 180px;
    z-index: 1;
    background: $color_sidebar;
    //border-right: 1px solid $palette_4;

    &.akd-sidebar {
      .ant-menu {
        background: inherit;
        color: $text_color_bg;

        .ant-menu-item,
        .ant-menu-submenu-title {
          color: $text_color;

          &:hover {
            background-color: $palette_2 !important;
            color: $text_color_bg;
          }

          & a:hover {
            text-decoration: none !important;
          }

          &.ant-menu-item-selected {
            background: $palette_3;
            color: $text_color_bg;
          }
        }

        // submenu coloring
        // .ant-menu-submenu-open .ant-menu-submenu-title {
        //   background: $palette_3;
        //   color: $text_color_bg;
        // }
      }
    }
  }
}