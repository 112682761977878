@import "../../style/variables.scss";

.akd-header {
  height: 12vh;
  max-height: 80px;
  padding: 0px;
  border-bottom: 1px solid $palette_3;
  position: 'fixed';
  width: '100%';
  z-index: 1000;

  .ant-menu {
    height: 100%;
    background-color: $color_topbar;
    display: flex;
    align-items: center;

    .home-logo {
      min-width: 200px;
      width: 200px;
      height: 100%;
      font-size: 1.3rem;
      color: $text_color;

      a {
        display: flex;

        &,
        img {
          height: 100%;
        }

        .site-name {
          margin: auto;
        }
      }

      &:hover {
        color: rgba($text_color, 0.5) !important;
      }
    }

    .page-name {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: default;

      &,
      .ant-typography {
        color: $text_color;
      }
    }

  }
}