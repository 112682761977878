.log_viewer {


    &.ant-card {
        .ant-card-head {
            min-height: 2rem;
        }

        .ant-card-body {
            padding: 4px 24px;
        }
    }

    .log_viewer_table {
        max-height: 75vh;
        overflow-y: auto;

        .ant-table .ant-table-container .ant-table-row {
            &.log-viewer-row-error {
                background-color: #ffccc7;
            }

            &.log-viewer-row-warn {
                background-color: #fff8d7;
            }

            &.log-viewer-row-info {
                background-color: #e6fffb;
            }

            &.log-viewer-row-debug {
                background-color: #ececec;
            }

            .ant-table-cell {
                &:nth-child(1) {
                    min-width: 150px;
                }

                padding: 0 8px;
                font-size: 0.75rem;

                pre {
                    margin: 0;
                }
            }
        }
    }
}