.tableDiv {
  position: relative;
  width: 100%; /* Ensure the div takes full width */
  height: 100%; /* Ensure the div takes full height */
  overflow: hidden; /* Hide default scrollbars */
  cursor: all-scroll; /* Show grab cursor */
  user-select: none; /* Prevent text selection while dragging */
}
.scrollable-wrapper {
  overflow-x: scroll; /* Enable horizontal scrolling */
  overflow-y: scroll; /* Enable vertical scrolling */
  width: 100%;
  height: 100%;
  position: relative; /* Position inside the container */
  max-height: 80vh; /* Ensure the container is scrollable vertically */
  /* Custom scrollbar styles for Webkit browsers (Chrome, Safari) */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
.scrollable-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.scrollable-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.scrollable-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scrollable-content {
  display: inline-block; /* Enable horizontal scroll */
  min-width: 100%; /* Ensure the content takes full width */
  min-height: 80%; /* Ensure the content takes full height */
}

.table-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust as needed */
  overflow: hidden; /* Hide default scrollbars */
}

.table-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; /* Hide default scrollbars */
  cursor: all-scroll; /* Indicate that both directions can be scrolled */
  position: relative;
}