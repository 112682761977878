@import "./variables.scss";

html,
body,
#root {
  height: 100%;
  margin: 0;
}

$body-bg: #EEE;

// Global Card Layout style
// .ant-card {
//   background: $body-bg;
// }

// .ant-card-body {
//   background: $body-bg;
//   flex-grow: 1;
// }

// Global Tabs style
.ant-tabs {
  border-color: #EEE;

  .ant-tabs-tab {
    color: $palette_1;

    &:hover {
      color: $palette_2 !important;
    }
  }
}

.ant-tabs-tab.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: $palette_2 !important;
  }

}

.ant-tabs-ink-bar {
  border-bottom: 1px solid $palette_2;
}

.ant-dropdown-menu-item-selected {
  color: $palette_1 !important;
  background-color: $palette_2 !important;
}

.ant-dropdown-menu {
  background-color: $palette_3 !important;
}

.oicstat-data-table {
  .oicstat-column-d_value {
    text-align: right;
  }
}

.oicstat-data-table .ant-table {
  table-layout: auto;
  width: 100%;
}