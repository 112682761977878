$palette_1: #000;
$palette_2: #0b61f4;
$palette_3: #519BD6;
$palette_4: #2F83C5;
$palette_4_lighten: #d4e6f5;

// $color_sidebar: rgba($palette_4, 1);
$color_sidebar: $palette_4_lighten;
$color_topbar: $palette_4_lighten;
// $color_background: rgba($palette_4, 1);
$color_background: #ddd;
$text_color: #000;
$text_color_bg: #fff;
